.titleSection {
  width: 95%;
  &:before {
    content: "";
    margin-top: 30px;
  }
  &:after {
    content: "";
    height: 2px;
    background: black;
    margin-bottom: 30px;
  }
  label span {
    font-size: 1.4em;
    font-weight: bold;
  }
}