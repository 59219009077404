.titleSectionShow {
  width: 95%;
  font-weight: bold;
  span {
    font-size: 1.5em;
    color: #304966;
  }
}

.text-field-inline-loan-show {
  display: inline-block;
  margin: auto 20px;
}
